<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-13 09:24:13
 * @LastEditTime: 2023-06-30 10:24
 * @Descripttion: 【数据统计】学情统计
-->
<style lang="scss" scoped>
.academic-sentiment {
    @include innerPage(54px 0 48px 15px);
    @include pageTitle(0);
    overflow: hidden;
    overflow-y: auto;

    .page-title {
        flex-direction: column;
        align-items: flex-start;

        .search-form {
            margin-top: 40px;
        }
    }

    .analyse {
        &-wrapper {
            width: 72.61%;
            max-width: 1148px;
            min-width: 980px;
            height: 464px;
            margin-top: 44px;
            @include flexBox(space-between);
        }

        &-rate,
        &-trend {
            background: #f0f2f7;
            height: 100%;
            border-radius: 10px;
            box-sizing: border-box;

            .label {
                font-size: 16px;
                line-height: 24px;
                color: #535353;
            }
        }

        &-rate {
            width: 32.87%;
            max-width: 374px;
            position: relative;
            padding: 116px calc((32.87% - 220px) / 2) 0;

            .center-text {
                width: 220px;
                height: 220px;
                position: absolute;
                left: 50%;
                top: 116px;
                flex-direction: column;
                transform: translateX(-50%);
                @include flexBox(center);

                .percentage {
                    font-size: 36px;
                    line-height: 1em;
                    color: #6340c8;

                    span {
                        font-size: 14px;
                    }

                    &.success {
                        color: #28ca96;
                    }

                    &.danger {
                        color: #ee4f65;
                    }

                }
            }

            .label {
                margin-top: 68px;
                text-align: center;
            }
        }

        &-trend {
            width: 65.05%;
            max-width: 746px;

            &--echarts {
                width: 100%;
                height: calc(100% - 80px);
            }

            .label {
                padding-left: 72px;
                margin-top: 24px;
            }
        }
    }
}
</style>

<template>
    <section class="academic-sentiment">
        <div class="page-title">
            <h3>学情统计</h3>
            <div class="search-form">
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-select v-model="searchForm.grade" placeholder="选择年级" @change="chooseGrade">
                            <el-option label="全部" :value="0" />
                            <el-option v-for="item in gradeList" :key="item.sccla_grade" :label="item.sccla_name"
                                :value="item.sccla_grade" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.class_id" placeholder="选择班级">
                            <el-option label="全部" :value="0" />
                            <el-option v-for="item in classList" :key="item.sccla_id" :label="item.sccla_name"
                                :value="item.sccla_id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.sysub_id" placeholder="选择科目">
                            <el-option label="全部" :value="0" />
                            <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                                :value="item.sysub_id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.scro_type" placeholder="选择班级" @change="getStatistics">
                            <el-option label="做题数据分析" :value="1"></el-option>
                            <el-option label="作业数据分析" :value="2"></el-option>
                            <el-option label="考试成绩数据分析" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" size="medium" @click="getStatistics">查询</el-button>
            </div>
        </div>
        <div class="analyse-wrapper">
            <div class="analyse-rate">
                <el-progress class="percentage-progress" :class="{ success: analyseType == 2, danger: analyseType == 3 }"
                    :width="220" :stroke-width="30" type="circle" stroke-linecap="butt"
                    :percentage="statisticsData.rate || 0" :show-text="false" />
                <div class="center-text">
                    <p class="pf_bold percentage" :class="{ success: analyseType == 2, danger: analyseType == 3 }">
                        {{ statisticsData.rate || 0 }}
                        <span class="pf">%</span>
                    </p>
                </div>
                <p class="label">{{ analyseType == 1 && '做题正确率' || analyseType == 2 && '作业完成率' || '考试合格率'
                }}</p>
            </div>
            <div class="analyse-trend">
                <div class="analyse-trend--echarts" ref="trend"></div>
                <p class="label">
                    近七{{ analyseType == 1 && '日做题正确率' || analyseType == 2 && '日作业完成率' || '次考试合格率' }}趋势图
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import { $studyAnalysis } from "@api/statistics"
import { mapState } from "vuex"
export default {
    name: 'statistics_academicSentiment',
    computed: {
        ...mapState('common', ['gradeClass', 'subjectList'])
    },
    watch: {
        gradeClass: {
            handler(val) {
                if (!this.$isEmpty(val)) {
                    this.gradeList = [...val];
                }
            },
            immediate: true,
        }
    },
    data() {
        return {
            gradeList: [], // 年级列表
            classList: [], // 班级列表
            searchForm: {
                grade: 0,
                class_id: 0,
                sysub_id: 0,
                scro_type: 1,
            },
            statisticsData: {},
            analyseType: 0,
        }
    },
    created() {
        this.getStatistics();
    },
    methods: {
        /** 班级切换 */
        chooseGrade(val) {
            if (!val) { this.classList = []; this.searchForm.class_id = 0 }
            this.classList = [];
            this.gradeList.map(item => {
                if (item.sccla_grade == val) this.classList = [...item.children]
            })
        },
        /** 获取统计数据 */
        async getStatistics() {
            let params = { ...this.searchForm };
            if (!params.grade) delete params.grade;
            if (!params.class_id) delete params.class_id;
            if (!params.sysub_id) delete params.sysub_id;

            let { data } = await $studyAnalysis(params);
            this.statisticsData = { ...data };
            this.$nextTick(() => {
                let { chart } = data;
                this.analyseType = params.scro_type;
                this.trendEcharts(chart.x_data || [], chart.y_data || []);
            });
            this.$forceUpdate();
        },

        /** 成绩趋势图表 */
        trendEcharts(xAxisData, seriesData) {
            let echarts = this.$echarts.init(this.$refs.trend);
            echarts.resize();
            echarts.clear();
            let color = this.analyseType == 2 && '#2ac293' || this.analyseType == 3 && '#f66478' || '#6340c8'
            let colorRgb = this.analyseType == 2 && '42, 194, 147' || this.analyseType == 3 && '246, 100, 120' || '152, 126, 232'
            let shadowColor = this.analyseType == 2 && 'rgba(42, 194, 147, 0.53)' || this.analyseType == 3 && 'rgba(246, 100, 120, 0.53)' || 'rgba(99, 64, 200, 0.53)'
            let option = {
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: color,
                    extraCssText: 'border-radius: 8px',
                    borderColor: color,
                    padding: [5, 10],
                    formatter: '{c}%',
                    textStyle: {
                        color: '#fff',
                        height: '24px',
                        fontSize: 14,
                        fontFamily: 'PingFang SC'
                    },
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(152, 126, 232, 0.26)'
                        }
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData,
                    offset: 18,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    nameTextStyle: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    offset: 5,
                    max: 100,
                    min: 0,
                    interval: 10,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            // 使用深浅的间隔色
                            color: ['rgba(233,233,244,0.5)'],
                            type: 'dashed'
                        }
                    },
                    nameTextStyle: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                grid: {
                    show: false,
                    bottom: '15%',
                },
                series: [
                    {
                        type: 'line',
                        data: seriesData,
                        symbol: 'circle',
                        showSymbol: false,
                        symbolSize: 10,
                        itemStyle: {
                            color: color,
                            borderWidth: 2,
                            borderColor: '#fff',
                            shadowColor: shadowColor,
                            shadowBlur: 20
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: `rgba(${colorRgb}, 0.8)`
                                },
                                {
                                    offset: 1,
                                    color: `rgba(${colorRgb}, 0)`
                                }
                            ])
                        },
                    }
                ],
            };
            echarts.setOption(option);
        },
    },
}
</script>